import React from 'react';

import { TitleCta, TitleCtaProps } from '../components/molecules/title-cta';
import { Section } from '../components/molecules/section';
import { InstagramStories, InstagramStoriesProps } from '../components/organisms/instagram-stories';
import { SimpleCardList, SimpleCardListProps } from '../components/organisms/simple-card-list';
import { MediaCard, MediaCardProps } from '../components/organisms/media-card';
import {
  HomepageTextContentWithMedia,
  HomepageTextContentWithMediaProps,
} from '../components/molecules/text-content-with-media';
import { Slider, SliderProps } from '../components/organisms/slider';
import { EditorialContentList, EditorialContentListProps } from '../components/molecules/editorial-content-list';
import { Theme, useTheme } from '@emotion/react';

type NewsSection = {
  type: 'news';
  title: TitleCtaProps;
  content: EditorialContentListProps;
};

type SliderSection = {
  type: 'slider';
  slides: SliderProps;
};

type SliderQuoteSection = {
  type: 'sliderquote';
  slides: SliderProps;
};

type SimpleCardSection = {
  type: 'simplecard';
  title: TitleCtaProps;
  card: SimpleCardListProps;
};

type LargeProgramSection = {
  type: 'largeprogram';
  title: TitleCtaProps;
  card: MediaCardProps;
};

type InstagramSection = {
  type: 'instagram';
  stories: InstagramStoriesProps;
};

type ContentImageSection = {
  type: 'contentimage';
  card: HomepageTextContentWithMediaProps;
};

export type HomepageSection =
  | NewsSection
  | SliderSection
  | SliderQuoteSection
  | SimpleCardSection
  | LargeProgramSection
  | InstagramSection
  | ContentImageSection;

export type HomepageProps = {
  sections: HomepageSection[];
};

export const HomepageTemplate: React.FC<HomepageProps> = props => {
  const theme = useTheme();
  return (
    <>
      {props.sections?.map((section, index) => {
        const id = `${section.type}-${index}`;

        return mapSectionToComponent(section, id, theme);
      })}
    </>
  );
};

function mapSectionToComponent(section: HomepageSection, id: string, theme: Theme): React.ReactElement {
  switch (section.type) {
    case 'instagram':
      return (
        <Section id={id} marginPreset="all" firstChildMarginPreset="bottom" key={id}>
          <InstagramStories {...section.stories} />
        </Section>
      );

    case 'contentimage':
      return (
        <Section id={id} firstChildMarginPreset="bottom" key={id}>
          <HomepageTextContentWithMedia {...section.card} />
        </Section>
      );

    case 'largeprogram':
      return (
        <Section id={id} marginPreset="all" firstChildMarginPreset="bottom" key={id}>
          <TitleCta {...section.title} />
          <MediaCard {...section.card} />
        </Section>
      );

    case 'news':
      return (
        <Section
          id={id}
          marginPreset="all"
          firstChildMarginPreset="bottom"
          background={theme.color.palette['gray-lighten-3']}
          key={id}
        >
          <TitleCta {...section.title} />
          <EditorialContentList {...section.content} />
        </Section>
      );

    case 'simplecard':
      return (
        <Section id={id} marginPreset="bottom" firstChildMarginPreset="bottom-sides" key={id}>
          <TitleCta {...section.title} />
          <SimpleCardList {...section.card} />
        </Section>
      );

    case 'slider':
      return (
        <Section id={id} firstChildMarginPreset="bottom" key={id}>
          <Slider {...section.slides} />
        </Section>
      );

    case 'sliderquote':
      return (
        <Section
          id={id}
          marginPreset="sides"
          firstChildMarginPreset="bottom"
          background={theme.color.palette['gray-lighten-3']}
          key={id}
        >
          <Slider {...section.slides} />
        </Section>
      );
  }
}
