import { graphql } from 'gatsby';
import React from 'react';
import styled from '@emotion/styled';
import { Heading } from '../atoms/heading';
import { Image, ImageProps } from '../atoms/image';

export type SimpleCardProps = {
  title?: string;
  text: string;
  image?: ImageProps;
};

const Wrapper = styled.div`
  text-align: center;
`;

const Content = styled.div`
  margin: ${({ theme }) => theme.spacing.unit * 5}px;
`;

export const SimpleCard: React.FC<SimpleCardProps> = props => {
  return (
    <Wrapper>
      <Image {...props.image} />
      <Content>
        {props.title && <Heading level="h3" text={props.title} />}
        <p>{props.text}</p>
      </Content>
    </Wrapper>
  );
};

export const simpleCardFragment = graphql`
  fragment SimpleImageCard on cms_ComponentAtomSimpleCard {
    media {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
    mobile_media {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FIXED)
        }
      }
    }
    text
    title
  }
`;
