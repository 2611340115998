import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import React from 'react';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { Heading } from '../atoms/heading';
import { Image, ImageProps } from '../atoms/image';
import { SocialButton } from '../molecules/social-button';

export type InstagramStoriesProps = {
  images: ImageProps[];
  title: string;
};

const Wrapper = styled.div``;

const StoriesList = styled.div`
  display: flex;
  align-items: baseline;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    justify-content: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: flex;
    overflow-x: scroll;
  }
  overflow: hidden;

  > * {
    object-fit: contain;
  }

  > * + * {
    margin-left: 20px;
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  max-width: 230px;
  margin: 0 auto 20px;

  a:hover i {
    background-color: ${({ theme }) => theme.color.primary};

    svg {
      fill: ${({ theme }) => theme.color.palette['pure-white']};
    }
  }
`;

function getInstagramImageSize(index: number) {
  switch (index % 6) {
    case 0:
    case 4:
      return '231px';
    case 1:
    case 3:
      return '142px';
    case 2:
    case 5:
      return '199px';
  }
}

export const InstagramStories: React.FC<InstagramStoriesProps> = props => {
  const { isDesktop } = useBreakpoints();

  return (
    <Wrapper>
      <StyledHeading level="h3">
        {props.title}
        <SocialButton kind="instagram" linkValue="keringfoundation" text="KeringFoundation" />
      </StyledHeading>

      <StoriesList>
        {props.images.map((image, index) => (
          <Image
            key={image.id || image.title || image.alt}
            css={{ width: getInstagramImageSize(index), ...(!isDesktop && { flexShrink: 0 }) }}
            {...image}
          />
        ))}
      </StoriesList>
    </Wrapper>
  );
};

export const instagramStoriesFragment = graphql`
  fragment InstagramStories on cms_ComponentHomepageInstagramStories {
    __typename
    text_before_instagram
    stories {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(breakpoints: [143, 199, 231], placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`;
