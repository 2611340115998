import React from 'react';

import { CitationSliderFragment, ImageSliderFragment } from '../../graphql-types';
import { SliderProps } from '../components/organisms/slider';
import { Slide } from '../components/molecules/slide';
import { AvailableLocales } from '../locale';
import { mapFragmentToImageProps } from './image.mapper';
import { safeMap } from './utils';

export function mapImageSliderFragmentToSliderProps(
  fragment: ImageSliderFragment,
  locale: AvailableLocales
): SliderProps {
  return {
    children: safeMap(slide => {
      const props = {
        mdContent: slide.content ?? '',
        cta: {
          text: slide.cta_text ?? '',
          link: slide.cta_link ?? '',
          title: slide.cta_title ?? '',
        },
        background: mapFragmentToImageProps(slide.background_image, locale, slide.background_image_mobile),
      };

      return <Slide {...props} />;
    }, fragment.slides),
  };
}

export function mapQuoteSliderFragmentToSliderProps(
  fragment: CitationSliderFragment,
  locale: AvailableLocales
): SliderProps {
  return {
    images: {
      firstSet: safeMap(media => mapFragmentToImageProps(media, locale), fragment.media_first_set),
      secondSet: safeMap(media => mapFragmentToImageProps(media, locale), fragment.media_second_set),
    },
    children: safeMap(slide => {
      const props = {
        mdContent: slide.content ?? '',
      };

      return <Slide {...props} />;
    }, fragment.slides),
  };
}
