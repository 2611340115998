import { graphql } from 'gatsby';
import React from 'react';
import styled from '@emotion/styled';
import { SimpleCard, SimpleCardProps } from '../molecules/simple-card';

export type SimpleCardListProps = {
  cards: SimpleCardProps[];
};

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid ${({ theme }) => theme.color.palette['pure-black']};

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;

    > * + * {
      border-left: 1px solid ${({ theme }) => theme.color.palette['pure-black']};
    }
  }
`;

const CardWrapper = styled.div`
  flex: 1 1 0;
`;

export const SimpleCardList: React.FC<SimpleCardListProps> = props => {
  return (
    <ListWrapper>
      {props.cards.map(card => (
        <CardWrapper key={card.title}>
          <SimpleCard {...card} />
        </CardWrapper>
      ))}
    </ListWrapper>
  );
};

export const simpleCardListFragment = graphql`
  fragment SimpleImageCardList on cms_ComponentHomepageSimpleImageCardList {
    __typename
    text
    title
    SimpleCard {
      ...SimpleImageCard
    }
    link {
      ...Link
    }
  }
`;
