import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import styled from '@emotion/styled';

import { CTALink, CTALinkProps } from '../atoms/cta-link';
import { Heading } from '../atoms/heading';
import { TagList } from '../molecules/tag-list';
import { Media, MediaProps } from '../molecules/media';

export type MediaCardProps = {
  title: string;
  excerpt: string;
  tags?: string[];
  ctaLink?: Partial<CTALinkProps>;
  media?: MediaProps;
};

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.secondary};

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;
  }
`;

const TextContent = styled.div`
  padding: ${({ theme }) => theme.spacing.unit * 5}px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
  }
`;

const ReadMoreWrapper = styled.div`
  margin-top: auto;
`;

export const MediaCard: React.FC<MediaCardProps> = props => {
  const intl = useIntl();

  return (
    <CardWrapper>
      <TextContent>
        <Heading level="h3" text={props.title} />
        <p>{props.excerpt}</p>

        {props.tags && <TagList tags={props.tags} />}

        {props.ctaLink && (
          <ReadMoreWrapper>
            <CTALink {...props.ctaLink} color={'link'} text={intl.formatMessage({ id: 'read_more' })} uppercase />
          </ReadMoreWrapper>
        )}
      </TextContent>

      <div css={{ flexBasis: '50%' }}>{props.media && <Media {...props.media} />}</div>
    </CardWrapper>
  );
};

export const mediaCardFragment = graphql`
  fragment LargeProgram on cms_ComponentHomepageLargeProgram {
    __typename
    title
    text
    link {
      ...Link
    }
    program {
      ...ProgramLink
    }
  }
`;
